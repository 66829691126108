import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import MoreIcon from '@mui/icons-material/MoreVert';
import HideOnScroll from './components/HideOnScroll'
import T from '../T'
import { Avatar, Grid } from '@mui/material';
import { AddchartOutlined, ContactsOutlined, HomeOutlined, ShareOutlined, SupervisorAccountOutlined } from '@mui/icons-material';
import Login from '../Login';
import IconMask from '../IconMask';
import { useTheme } from '@mui/system';
import app from '../../services';
const height = 60;
const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    paddingBottom: height,
    [theme.breakpoints.up('sm')]: {
      paddingTop: height,
      paddingBottom: 0,
    },
  },
  appBar: {
    position: 'fixed',
    bottom: 0,
    height: height,
    width: '100%',
    borderTop: '1px solid rgba(200,200,200,0.2)',
    borderBottom: '1px solid rgba(200,200,200,0.2)',
    backgroundColor: 'inherit',
    boxShadow: undefined,
    [theme.breakpoints.up('sm')]: {
      bottom: undefined,
      top: 0,
      boxShadow: '0px 0px 10px rgba(50,50,50,0.9)',
    },
  }
}));

function HeaderAppBar(props) {
  const classes = useStyles();
  const history = useHistory()
  const [path, setPath] = useState(history.location.pathname);
  const token = useSelector((state) => state.session.token);
  const theme = useTheme()
  const dispatch = useDispatch();

  const [showLogin, setShowLogin] = useState(!token)

  useEffect(() => {
    console.log('token', showLogin, token);
    if (!!token && showLogin) {
      setShowLogin(false);
      history.push('/')
    } else if (!token && !showLogin) {
      setShowLogin(true);
    }
    // console.log('history', history.location.pathname);
    return history.listen((location) => {
      // console.log(`You changed the page to: ${location.pathname}`)
      if (!token && location.pathname !== '/') {
        setShowLogin(true);
      } else if (location.pathname !== '/' && !showLogin) {
        setShowLogin(false);
      }
      setPath(location.pathname);
    })
  }, [history, token])
  // console.log('path-----', path)
  useEffect(() => {
    app.on('disconnect', (e) => {
      console.log('e', e)
    })
    app.reAuthenticate().then(() => {
      // show application page
      console.log('authenticate')
    }).catch(() => {
      // show login page
      console.log('no authenticate')
      setShowLogin(true);
      dispatch({ type: 'UNSET_USER', payload: null });

    });
  }, [])
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={classes.appBar}>
        {
          [
            // { name: 'Conexiones', path: '/directory', icon: 'assets/icons/global-network.svg' },
            // { name: 'Conexiones', path: '/registers', icon: 'assets/icons/radar.svg' },
            { name: 'Conexiones', path: '/devices', icon: 'assets/icons/radar.svg' },
            { name: 'Conexiones', path: '/', icon: 'assets/icons/iteration.svg' },
            { name: 'Conexiones', path: '/video-call', icon: 'assets/icons/engineer.svg' },
            { name: 'Conexiones', path: '/config', icon: 'assets/icons/settings.svg' },
          ].map((route, index) => {
            return (
              <Grid key={index} item xs>
                <Button onClick={() => { history.push(route.path) }} fullWidth size='large' style={{ height: '100%' }}>
                  <IconMask color={route.path === path ? theme.palette.primary.main : 'gray'} url={route.icon} />
                </Button>
              </Grid>
            )
          })
        }
      </Grid>
      <Box className={classes.container}>
        {props.children}
      </Box>
      <Toolbar />
      <Login
        open={showLogin}
        setShowLogin={setShowLogin}
      />
    </React.Fragment>
  );
}


HeaderAppBar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HeaderAppBar
import React, { useEffect, useState } from 'react';
import { Box, debounce, inputLabelClasses, ListItem, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import models from '../../constants/models';
import app from '../../services';
import { makeStyles, useTheme } from '@mui/styles';
import IconMask from '../IconMask';
import { useSelector } from 'react-redux';
import CatalogsIconButton from '../customForms/CatalogsIconButton';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const delaySearch = debounce((callback) => {
  callback();
}, 300)
const useStyles = makeStyles(theme => ({
  label: {
    '&:hover': {
      '&:before': {
        borderBottom: ['rgba(0, 188, 212, 0.7)', '!important'],
      }
    },
  },
  underline: {
    '&:hover': {
      '&:before': {
        borderBottom: ['rgba(0, 188, 212, 0.7)', '!important'],
      }
    },
    '&:before': {
      borderBottom: 'rgba(0, 188, 212, 0.7)',
    }
  }
}))
export default function SearchSelect({ control, id, field, defaultValue, modelBase, variant, ...props }) {
  const theme = useTheme();
  const model = Object.values(models).find(e => e.id === field?.relation?.id_model) || {};
  const token = useSelector(state => state.session.token);
  const user = useSelector(state => state.session.globalUser);
  const institution = useSelector(state => state.session.institution);
  const service = app.service(field?.relation?.vc_nameTable)
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const isAdmin = institution?.roles?.find(e => e.id_rol === 'cab3b86a-09db-4808-85ff-bc235fd7b6f9' || e.id_rol === '663536e2-bdb4-4887-a313-c42d3e00bc37')
  const searchData = async (searchText) => {
    // await app.reAuthenticate()
    setOptions([]);
    const filtersDefault = [];

    model?.defaultSystem?.map((item, index) => {
      switch (true) {
        case (item.param === 'institution'): {
          filtersDefault.push({
            [item.vc_field]: institution[item.key]
          })
          break;
        }
        case (item.param === 'user'): {
          console.log({
            [item.vc_field]: (user || {})[item.key],
            [item.vc_field]: user
          })
          filtersDefault.push({
            [item.vc_field]: user[item.key]
          })
          break;
        }
        case (item.type === 'static'): {
          filtersDefault.push({
            [item.vc_field]: item.value
          })
          break;
        }
        default: { }
      }
    });
    let validation = true;
    field?.relation?.defaultSystem?.map((item, index) => {
      switch (true) {
        case (item.param === 'institution'): {
          filtersDefault.push({
            [item.vc_field]: institution[item.key]
          })
          break;
        }
        case (item.param === 'user'): {
          filtersDefault.push({
            [item.vc_field]: user[item.key]
          })
          break;
        }
        case (item.type === 'static'): {
          filtersDefault.push({
            [item.vc_field]: item.value
          })
          break;
        }
        case (item.type === 'default'): {
          console.log('modelBase', modelBase, item)
          if (modelBase.defaultValues && modelBase.defaultValues[item.key]) {
            filtersDefault.push({
              [item.vc_field]: modelBase.defaultValues[item.key]
            })
          } else if (item.required) {
            console.log('valita pred', validation)
            validation = false;
          }

          break;
        }
        case (item.type === 'field'): {
          const el = document.getElementById(model?.name || '' + item.key);
          console.log('edddddd', el, el?.value);
          if (el?.value !== undefined && el?.value !== '' && el?.value !== null) {
            filtersDefault.push({
              [item.vc_field]: el?.value
            })
          } else if (item.required) {
            console.log('valita pred', validation)
            validation = false;
          }

          break;
        }
        default: { }
      }
    })
    const and = [...model?.and || [], ...filtersDefault]
    if (validation) {
      // const response = await service.find({
      //   id_institucion: institution?.id,
      //   query: {
      //     $limit: searchText.length > 3 ? 100 : 100,
      //     $skip: 0,
      //     ...(and.length ? { $and: and } : {}),
      //     // ...(model?.sort ? { $sort: model.sort } : {}),
      //     ...(model?.searchFields || []).length ? {
      //       $or: (model?.searchFields || []).map((searchField) => {
      //         return {
      //           [searchField.id]: {
      //             $like: '%' + searchText + '%'
      //           }
      //         }
      //       })
      //     } : {}
      //   }
      // });
      // console.log('response?.data', response);
      // let dataSorted = response?.data || [];
      // if (model?.sort)
      //   dataSorted.sort((a, b) => a[model?.sort] - b[model?.sort])
      // setOptions(dataSorted);

      let response = {};

      try {
        const params = {
          $limit: searchText.length > 3 ? 100 : 100,
          $skip: 0,
          ...(and.length ? { $and: and } : {}),
          // ...(model?.sort ? { $sort: model.sort } : {}),
          ...(model?.searchFields || []).length ? {
            $or: (model?.searchFields || []).map((searchField) => {
              return {
                [searchField.id]: {
                  $like: '%' + searchText + '%'
                }
              }
            })
          } : {}
        };
        var baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3030';
        console.log('query ---->', JSON.stringify(params));
        const url = `${baseURL}/${field?.relation?.vc_nameTable}?dataString=${JSON.stringify(params)}`;
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        // Verificar si la respuesta es exitosa (código de estado 2xx)
        if (res?.ok) {
          // Obtener la data
          response = await res?.json();
          // Manejar la data
          console.log('response', response);
        } else {
          // Manejar errores de la solicitud
          console.error('Error al realizar la solicitud:', res?.status, res?.statusText);
        }
        console.log('response?.data', response);
        let dataSorted = response?.data || [];
        if (model?.sort)
          dataSorted.sort((a, b) => a[model?.sort] - b[model?.sort])
        setOptions(dataSorted);
      } catch (error) {
        console.log('error', error);
        setOptions([]);
      }
    }

  }

  React.useEffect(() => {
    (async () => {
      if (defaultValue) {
        const respon = await service.get(defaultValue, {});
        // console.log('response get', respon);
        if (respon)
          setValue(respon)
      }
    })()
  }, [defaultValue]);
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }

    if (inputValue || open) {
      delaySearch(() => {
        searchData(inputValue);
      })
    }
  }, [inputValue]);

  const getLabel = (item => {
    let label = '';
    if (!item) return '';
    field?.relation?.fieldsDisplay.map((key, index) => {
      label = `${label}${(field?.relation?.objectPath ? (item[field?.relation?.objectPath] || {})[key] || '' : item[key]) || ''}${field?.relation?.fieldsDisplaySeparators[index] || ''}`
    });
    return label;
  })


  return (
    [
      <Box display={'flex'} flexDirection={'row'} position={'relative'} width={'100%'}>
        <Box paddingTop={2} style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <div style={{ backgroundColor: 'rgba(200,200,200,0.1)', boxShadow: '0px 0px 2px gray', borderRadius: 10, height: '100%', width: '100%' }} />
        </Box>
        <div style={{ flexGrow: 1, flexShrink: 1 }}>
          <Autocomplete
            key={id + 'selector'}
            fullWidth
            noOptionsText={'No hay coincidencias'}
            value={value || null}
            isOptionEqualToValue={(option, val) => {
              return (JSON.stringify(option) === JSON.stringify(val))
            }}
            onOpen={() => searchData(inputValue)}
            onChange={(event, newValue) => {
              setValue(newValue);
              // console.log('value', newValue);
            }}
            getOptionLabel={(option) => getLabel(option)}
            inputValue={inputValue || ''}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            filterOptions={(e) => e}
            readOnly={!!field.readOnly}
            render
            renderOption={(props, option) => {
              // console.log('render option', options);
              return (
                <ListItem
                  {...props}
                >
                  {
                    option.icon && <IconMask color={value === option ? theme.palette.primary.main : theme.palette.mode === 'dark' ? 'white' : 'black'} url={option.icon} size={25} />
                  }
                  <Typography>
                    {getLabel(option)}
                  </Typography>
                </ListItem>
              )
            }}
            renderInput={(params) => <TextField {...params}
              label={`${field.label}${field.required ? '*' : ''}`}
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "gray",
                  padding: '5px 10px',
                  [`&.${inputLabelClasses.shrink}`]: {
                    // set the color of the label when shrinked (usually when the TextField is focused)
                    padding: '0px 14px',
                  }
                }
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                placeholder: field.label,
                // endAdornment: isAdmin ?
                //   <Box style={{ pointerEvents: 'auto' }}>
                //     <CatalogsIconButton sizeButton={'small'} backgroundColor={'transparent'} size={20} model={model} icon={'assets/icons/addList.svg'} />
                //   </Box>
                //   :
                //   undefined,
                style: { padding: '6px 10px', backgroundColor: 'rgba(200,200,200,0)', borderRadius: 10 }
              }}
              // InputProps={{ classes: { underline: classes.underline } }}
              fullWidth
              variant={variant} />}
          />
        </div>
        <div style={{ flexGrow: 0, flexShrink: 1, width: 'auto' }}>
          <Box paddingTop={3} paddingRight={1}>
            {
              isAdmin && <CatalogsIconButton sizeButton={'small'} item={value || undefined} backgroundColor={'transparent'} size={20} model={model} color={theme.palette.mode === 'light' ? 'black' : 'white'} icon={!!value ? 'assets/icons/edit.svg' : 'assets/icons/add.svg'} />
            }
          </Box>
        </div>

      </Box>,
      <input hidden readOnly key={id} id={id} value={value ? value[field?.relation?.keyTable] : ''} />

    ]
  )
}

import React from 'react';
import './style.css';

export default function PrintForms(props) {
    return (
        <div >
            <table>
                <thead>
                    <tr>
                        <td>
                            <div className='empty-header'>
                                Header
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className='content'>
                                ....Content
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className='empty-footer'>
                                footer
                            </div>
                        </td>
                    </tr>
                </tfoot >
            </table >
            <div className='header'>
                repeat Header
            </div>
            <div className='footer'>
                repeat footer
            </div>
        </div >

    )
}
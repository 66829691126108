import { Dialog, Paper } from '@material-ui/core';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import firebase from '../../services/firebase';
import IconMask from '../IconMask';
import Scene3D from '../Scene3D';
import SubComponents from '../SubComponents';
import ViewersLevels from '../ViewersLevels';

const inputs = [
    {
        label: 'Tipo',
        type: 'text',
        key: 'vc_type',
        options: [
            { label: 'Área', value: 'Área' },
            { label: 'Equipo', value: 'Equipo' },
        ]
    },
    {
        label: 'Nombre',
        type: 'text',
        key: 'vc_name',
    },
    {
        label: 'Descripción',
        type: 'text',
        key: 'vc_description',
        multiline: true,
    },
    {
        label: 'Modelo',
        type: 'text',
        key: 'vc_model',
    },
    {
        label: 'No. serie',
        type: 'text',
        key: 'vc_serie',
    },
    {
        label: 'Coordenadas (X, Y)',
        type: 'text',
        key: 'vc_coordinates',
    }
];
const modes = [
    // { vc_name: 'Editar', mode: 'edit', icon: 'edit.svg' },
    // { vc_name: 'Integrar', mode: 'share', icon: 'share.svg' },
    // { vc_name: 'Control', mode: 'panel', icon: 'control-panel.svg' },
    { vc_name: 'Medición', mode: 'panel', icon: 'indicator.svg' },
    { vc_name: 'Manual', mode: 'manual', icon: 'manual.svg' },
    // { vc_name: 'Revisión', mode: 'review', icon: 'review.svg' },
    // { vc_name: 'Marcadores', mode: 'indicator', icon: 'indicator.svg' },
]
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
// const Transition2 = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });
const db = firebase.database().ref();
const demo = '11036800005F800000EACB000032CF0000000D07F708A1085C04FE04FD050008A908A808A6176E033F032F0366034100E300F000F902A901110127011E0330009600AA008B01BE079A084B080104FE04FD0500142E145814480AEF0AF00AF502B2033D01C605000CC402B4A22F'
const ModalItem = ({ selected, setSelected, ...props }) => {
    const [mode, setMode] = useState({ mode: 'selector' });
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
      defaultMatches: true,
    });
    useEffect(() => {
        if (!!selected && Object.values(selected).length === 0) {
            setMode({ mode: 'create' })
        }
    }, [selected])
    const onSave = () => {
        const edit = {};
        [...inputs,
        ...(selected.id_parent ? [
            {
                label: 'Id Contenedor',
                type: 'text',
                key: 'id_parent',
            },
            {
                label: 'Clave',
                type: 'text',
                key: 'vc_path',
                multiline: true,
            }] : [])].map(input => {
                const value = document.getElementById(input.key)?.value
                if (!!value) {
                    edit[input.key] = value;
                }
            })
        if (selected.id) {
            db.child('clients').child('items').child(selected.id).set(edit);
        } else {
            db.child('clients').child('items').push(edit);
        }
        setSelected(null);
        setMode({ mode: 'selector' })
    }

    const renderContent = () => {
        console.log('mode?.mode', mode?.mode)
        switch (true) {
            case mode?.mode === 'create':
            case mode?.mode === 'share':
            case mode?.mode === 'edit': {
                return (
                    <Dialog
                        open={!!selected}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>
                            {selected?.id ? 'Editar' : 'Agregar'}
                        </DialogTitle>
                        <DialogContent style={{ paddingTop: 10 }}>
                            {
                                selected?.id ?
                                    <TextField id={'id'} disabled fullWidth label={'Id'} type={'text'} placeholder='' defaultValue={selected?.id} style={{ marginBottom: 20 }} /> :
                                    null

                            }
                            {
                                selected?.id_parent ?
                                    <TextField id={'id_parent'} disabled fullWidth label={'Id Contenedor'} type={'text'} placeholder='' defaultValue={selected?.id_parent} style={{ marginBottom: 20 }} /> :
                                    null
                            }
                            {
                                inputs.map((input, index) => {
                                    switch (input.component) {
                                        default: {
                                            return (
                                                <TextField key={index} id={input.key} fullWidth label={input.label} type={input.type || 'text'} placeholder='Capture...' defaultValue={selected ? selected[input.key] : ''} style={{ marginBottom: 20 }} />
                                            )
                                        }
                                    }
                                })
                            }
                        </DialogContent>
                        <DialogActions>
                            {
                                selected?.id &&
                                <Button key={'integrar'} onClick={() => {
                                    setSelected({ id_parent: selected?.id, vc_path: `${selected?.vc_path || ''}/${selected?.id}` })
                                }} disabled={selected?.id_parent}>
                                    {'Integrar'}
                                </Button>
                            }
                            <Button key={0} onClick={() => {
                                console.log('selected', selected);
                                if (selected?.id_parent || !selected?.id) {
                                    setSelected(null);
                                }
                                setMode({ mode: 'selector' })
                            }}>
                                {'Cancelar'}
                            </Button>
                            <Button key={1} variant='contained' onClick={onSave}>
                                {'Guardar'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }

            default: {
                return (
                    <Dialog
                        open={!!selected}
                        TransitionComponent={Transition}
                        fullWidth
                        PaperComponent={Paper}
                    >
                        <DialogTitle>
                            {selected?.vc_name || mode?.vc_name || 'Selecciona'}
                        </DialogTitle>
                        <DialogContent style={{ paddingTop: 10 }}>
                            <Grid container justifyItems={'left'} style={{ borderRadius: 8, boxShadow: '0px 0px 5px gray', overflow: 'hidden' }}>
                                {
                                    modes.map((p, i) => {
                                        return (
                                            <Grid item key={i} xs padding={1} style={{ border: '0.5px solid rgba(200,200,200,0.5)', position: 'relative' }}>
                                                <div id={i}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Button onClick={() => {
                                                        console.log('mode select', p)
                                                        if (p.mode === 'share') {
                                                            setMode(p);
                                                            setSelected({ id_parent: selected?.id, vc_path: `${selected?.vc_path || ''}/${selected?.id}` })
                                                        } else {
                                                            setMode(p);
                                                        }
                                                    }} fullWidth style={{ width: '100%', height: 60 }}>
                                                        <IconMask url={`assets/icons/${p.icon}`} color={'gray'} size={'100'} um={'%'} />
                                                    </Button>
                                                    <Typography style={{ width: '100%', textAlign: 'center', whiteSpace: 'normal', fontSize: 11 }}>
                                                        {p.vc_name}
                                                    </Typography>
                                                    {
                                                        p.mode === 'review' &&
                                                        <Box item xs='auto' container style={{ display: 'flex', position: 'absolute', top: 0, right: 0, backgroundColor: 'white', borderRadius: '50%', flexDirection: 'column' }}>
                                                            <WarningAmberOutlined htmlColor='orange' />
                                                        </Box>
                                                    }
                                                </div>

                                            </Grid>

                                        )
                                    })
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setSelected(null)}>
                                {'Cancelar'}
                            </Button>
                        </DialogActions>
                    </Dialog >
                )
            }
        }
    }
    const renderModal = () => {
        console.log('mode------', selected, mode)
        switch (mode?.mode) {
            case ('indicator'): return <Scene3D selected={selected} />;
            case ('panel'): return <ViewersLevels selected={selected}/>;
            case ('review'): return <SubComponents parent={selected} showDetails={() => setMode({ vc_name: 'Marcadores', mode: 'indicator', icon: 'indicator.svg' })} />;
            default: break;
        }
    }
    return [
        renderContent(),
        <Dialog
            open={mode?.mode === 'indicator' || mode?.mode === 'panel' || mode?.mode === 'review'}
            TransitionComponent={Transition}
            fullScreen={!isMd}
            fullWidth={isMd}
            PaperProps={{ style: { padding: 0 } }}
            style={{ padding: 0 }}
        >
            <Grid item xs='auto' style={{ width: '100%' }}>
                <Grid item xs={12} container width={'100%'} style={{ display: 'flex', justifyContent: 'center', margin: '8px 0px', borderBottom: '0.5px solit gray' }}>
                    <Grid key='logo' item xs='auto' padding='4px 16px' container flexDirection={'row'}>
                        <Grid item xs='auto'>
                            <Avatar src="assets/images/logo192.png" style={{ boxShadow: '0px 0px 5px rgba(200,200,200,0.5)', borderRadius: '50%', backgroundColor: 'transparent', height: 30, width: 30, padding: 2 }} />
                        </Grid>
                        <Grid key='nombre' item xs='auto' padding='2px 5px' flexDirection={'column'}>
                            <Typography style={{ fontWeight: 700, fontSize: 18 }}>
                                {'Total Ground'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid key='space' item xs justifyItems={'center'} p={'3px 8px'}>
                    </Grid>
                    {/* <Grid key='actions' item xs='auto' p={'0px 8px'}>
                        <QRIconComponent key={0} />
                        <IconButton key={1} onClick={() => setSelected({})} size='small' style={{ color: 'black' }}>
                            <IconMask color='black' url={'/assets/icons/add.svg'} size={25} />
                        </IconButton>
                    </Grid> */}
                </Grid>
            </Grid>
            <DialogContent style={{ paddingTop: 10, margin: 0, padding: 0, position: 'relative' }}>
                {renderModal()}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMode(null)}>
                    {'Cancelar'}
                </Button>
            </DialogActions>
        </Dialog>
    ];
};

export default ModalItem;

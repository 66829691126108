import moment from "moment";
import app from "../services";
const initCatalogs = async function (catalog, callback) {
    const response = await app.service(catalog.service).find({
        query: {
            $limit: 20000,
            updatedAt: {
                $gt: new Date(catalog.lastUpdate).getTime()
            }
        }
    });
   
    // app.service(catalog.service).removeAllListeners('created', () => { });
    // app.service(catalog.service).on('created', (item) => {
    //     console.log('item created', item);
    //     callback({
    //         ...catalog,
    //         lastUpdate: moment(),
    //         data: [item]
    //     });
    // })
    // app.service(catalog.service).removeAllListeners('updated', () => { });
    // app.service(catalog.service).on('updated', (item) => {
    //     console.log('item update', item);
    //     callback({
    //         ...catalog,
    //         lastUpdate: moment(),
    //         data: [item]
    //     });
    // })
    callback({
        ...catalog,
        lastUpdate: moment(),
        data: response.data || []
    })
}

const common = {
    initCatalogs,
}
export default common;
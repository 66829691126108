import React from "react";
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Typography, Tooltip, IconButton, InputBase, Box, debounce, Autocomplete, TextField, Menu, Button, MenuItem } from '@mui/material';
import { Delete, Edit, Search as SearchIcon, PlaylistAddOutlined, Queue, MoreVert, DonutSmallOutlined } from '@mui/icons-material';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const delaySearch = debounce((callback) => {
  callback();
}, 50)
const EnhancedTableToolbar = ({ onSelectModel = () => { }, model, models = [], delay = true, selected, numSelected, title = '', setEditItem, onDelete = () => { }, onSearch = () => { }, searchText = '', actions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),

      }}
      style={{ justifyContent: 'space-between' }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {numSelected === 1 ? 'Seleccionado' : 'Seleccionados'}
        </Typography>
      ) : (
        <Box
          sx={{ flex: '1 1 1 100%' }}
          display={'flex'} flexDirection={'row'}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ display: models.length > 1 ? 'flex' : 'none' }}
          >
            {model?.title || ''}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {
              models.map((item, index) => {
                return (
                  <MenuItem key={index} onClick={() => { onSelectModel(item); handleClose(); }}>{item.title}</MenuItem>
                )
              })
            }
          </Menu>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              defaultValue={searchText}
              onChange={(e) => delay ? delaySearch(() => onSearch(e.target.value)) : onSearch(e.target.value)}
              placeholder="Buscar..."
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Box>
      )}
      <Box
        sx={{ flex: '1 1 1 100%' }}
        display={'flex'} flexDirection={'row'}>

        {
          numSelected === 1 && (
            <Box display={'flex'} flexDirection='row'>
              <Tooltip title="Editar">
                <IconButton onClick={() => selected.length === 1 ? setEditItem(selected[0]) : undefined}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Duplicar">
                <IconButton onClick={() => selected.length === 1 ? setEditItem({ ...selected[0], id: null }) : undefined}>
                  <Queue />
                </IconButton>
              </Tooltip>
              {
                !!model.link &&
                <Tooltip title="Dashboard">
                  <IconButton onClick={() => {
                    history.push(model.link + '?' + selected[0].id)
                  }}>
                    < DonutSmallOutlined />
                  </IconButton>
                </Tooltip>
              }
            </Box>

          )
        }
        {numSelected > 0 ? (
          <Box display={'flex'} flexDirection='row'>
            <Tooltip title="Borrar">
              <IconButton disabled={numSelected > 1} onClick={onDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip
            sx={{ flex: '1 0 1 100%' }}
            title="Agregar Nuevo">
            <IconButton onClick={() => setEditItem({})}>
              <PlaylistAddOutlined />
            </IconButton>
          </Tooltip>
        )
        }
        {/* <Tooltip
          title="Más..">
          <IconButton onClick={() => setEditItem({})}>
            <MoreVert />
          </IconButton>
        </Tooltip> */}
      </Box>

    </Toolbar >
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default EnhancedTableToolbar;
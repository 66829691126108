/* eslint-disable use-isnan */
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, colors, Divider, Grid, IconButton, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, Zoom } from '@mui/material';
import ReactApexChart from "react-apexcharts"
import app from '../../services';
import ChartAir from '../ChartAir/index.tsx';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import ReactECharts from 'echarts-for-react';  // or var ReactECharts = require('echarts-for-react');
import * as echarts from 'echarts';
import moment from 'moment';
import './itemDashboardFavorites.css';
import GaugeAnimated from '../GaugeAnimated';
import useMeasure from 'react-use-measure';
import { useTheme } from '@mui/styles';
import Papa from 'papaparse';
import Selector from '../ViewersLevels/Selector';
import measuresDefault from './measuresTotalView.json';
import IAReports from '../IAReports';
import common from '../../repositories/common';
import BatteryIndicator from '../BatteryIndicator';
import { Download, NotificationsActiveOutlined, UpdateDisabledOutlined } from '@mui/icons-material';
import DateRangePicker from '../../common/DateRangePicker';
import ButtonDateRange from '../../common/ButtonDateRange';
import { useSelector } from 'react-redux';
const colorsArray = ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'];
function ItemDashboard({ selected, defaultMeasure, lite = false, alarms = [] }) {
  const token = useSelector(state => state.session.token);
  const [limitsRange, setLimitsRange] = useState([moment().add(-3, 'months'), moment().add(1, 'days')]);
  const [loadingData, setLoadignData] = useState({ loading: false, error: null });
  const [bat, setBat] = useState(null);
  const [range, setRange] = useState([moment().add(-1, 'days'), moment()]);
  const [lastDataDate, setLastDataDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refChart, boundInner] = useMeasure()
  const theme = useTheme()
  const [typesMeasures, setTypesMeasures] = useState(defaultMeasure ? [defaultMeasure] : []);
  const [measureSelect, setMeasureSelect] = useState();
  const [measuresSelected, setMeasuresSelected] = useState([]);
  const [customConfig, setCustomConfig] = useState({ options: {}, series: [] });
  const events = app.service('events');
  const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  // console.log('range .---------->', range);
  const getDataRetry = async () => {
    // let response = null;
    // if (!token) return;
    // try {
    //   setLoadignData({ loading: true });
    //   response = await events.find({
    //     query: {
    //       selected,
    //       measuresSelected: measuresSelected || [],
    //       $limit: 30000,
    //       $skip: 0,
    //       $where: [{ id_dispositivoArea: selected?.id, }],
    //       $or: {
    //         vc_field: [...measuresSelected.map((meas) => meas.vc_fieldName), ...!!typesMeasures.find(e => e.vc_fieldName === 'BAT') ? ['BAT'] : []]
    //       },
    //       // $between: [moment(!!limitsRange[0] && range[0] * 1 < limitsRange[0] * 1 ? limitsRange[0] : range[0]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), moment(!!limitsRange[1] && range[1] * 1 > limitsRange[1] * 1 ? limitsRange[1] : range[1]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')], // Agrega el rango de fechas aquí
    //       $between: [moment(!!limitsRange[0] && range[0] * 1 < limitsRange[0] * 1 ? limitsRange[0] : range[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(!!limitsRange[1] && range[1] * 1 > limitsRange[1] * 1 ? limitsRange[1] : range[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')], // Agrega el rango de fechas aquí
    //       $order: [['updatedAt', 'DESC']]
    //     }
    //   });
    //   setLoadignData({ loading: false });
    // } catch (error) {
    //   setLoadignData({ loading: false, error: error });
    //   console.log('error', error);
    // }
    // // console.log('response', response);

    // return response;

    let response = null;
    console.log('entroooooooooooooooo');
    try {
      setLoadignData({ loading: true });
      const params = {
        // selected,
        // measuresSelected: measuresSelected || [],
        $limit: 30000,
        $skip: 0,
        $where: [{ id_dispositivoArea: selected?.id, }],
        $or: {
          vc_field: [...measuresSelected.map((meas) => meas.vc_fieldName), ...!!typesMeasures.find(e => e.vc_fieldName === 'BAT') ? ['BAT'] : []]
        },
        // $between: [moment(!!limitsRange[0] && range[0] * 1 < limitsRange[0] * 1 ? limitsRange[0] : range[0]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), moment(!!limitsRange[1] && range[1] * 1 > limitsRange[1] * 1 ? limitsRange[1] : range[1]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')], // Agrega el rango de fechas aquí
        $between: [moment(!!limitsRange[0] && range[0] * 1 < limitsRange[0] * 1 ? limitsRange[0] : range[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(!!limitsRange[1] && range[1] * 1 > limitsRange[1] * 1 ? limitsRange[1] : range[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')], // Agrega el rango de fechas aquí
        $order: [['updatedAt', 'DESC']]
      }
      var baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3030';
      console.log('query ---->', JSON.parse(JSON.stringify(params)));
      const url = `${baseURL}/events?dataString=${JSON.stringify(params)}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      // Verificar si la respuesta es exitosa (código de estado 2xx)
      if (res?.ok) {
        // Obtener la data
        response = await res?.json();
        // Manejar la data
        console.log('response', response);
      } else {
        // Manejar errores de la solicitud
        console.error('Error al realizar la solicitud:', res?.status, res?.statusText);
      }
      setLoadignData({ loading: false });
    } catch (error) {
      setLoadignData({ loading: false, error: error });
      console.log('error', error);
    }
    return response;
  }
  const getEvents = async (animationLoad) => {
    if (animationLoad) {
      setCustomConfig({ ...customConfig, options: null, series: [] })
      setLoading(true);
    }
    // console.log('measure selected', measuresSelected);
    if (measuresSelected.length) {
      let response = null;

      response = await getDataRetry();

      if (!response?.data) {
        console.log('response error', response);
        return;
      }
      const valuesResponse = (response.data || []);
      valuesResponse.sort(function (a, b) { return moment(a.createdAt) - moment(b.createdAt) })
      const bat = ([...valuesResponse].reverse()[0] || {})?.registros?.find(e => e.vc_field === 'BAT')?.nu_value || null;
      setBat(bat);
      let gauge = {};
      const categories = valuesResponse.map(e => moment(e.createdAt).format('DD-MM-YY HH:mm'));
      const series = [];
      let lastObj = null;
      measuresSelected.map((meas, indexMeas) => {
        let data = [];
        let media = { average: 0, total: 0, length: 0 };
        let min = null;
        let max = null;
        let last = null;
        valuesResponse.map((event, index) => {
          const item = event.registros.sort(function (a, b) { return moment(b.createdAt) - moment(a.createdAt) }).find(e => e.vc_field === meas.vc_fieldName);
          if (item?.nu_value !== undefined) {
            let val = item.nu_value * 1;
            if (val !== NaN) {
              lastObj = { ...item, createdAt: event.createdAt }
              last = val
              data.push([moment(event.createdAt).format('DD-MM-YY HH:mm:ss'), val, event.createdAt])

            }
            // console.log('value------->', min, val, max);
            if (val >= max || max === null) {
              // console.log('entro a max');
              max = val;
            }
            if (val <= min || min === null) {
              // console.log('entro a min');
              min = val;
            }
            if (val !== NaN) {
              // console.log('entro a average');
              const mediaEdit = {
                average: ((media.total + val) / (media.length + 1)).toFixed(2),
                total: (media.total + val),
                length: (media.length + 1),
              }
              media = mediaEdit;
            }

          }
        });
        series.push({
          name: meas?.vc_nombre,
          data: data,
          measure: meas,
          color: colorsArray[indexMeas],
          min: meas.nu_min || null,
          max: meas.nu_max || null,
          gauge: {
            color: last > media.average ? '#FB0000' : colorsArray[indexMeas],
            value: last,
            max,
            min,
            ...media
          }
        })
      })

      let min = [...series].map(e => e.min).sort(function (a, b) { return a - b })[0] || null;
      // console.log('min', min);
      if (min !== null) {
        gauge.min = {
          value: min,
          type: 'range'
        }
      } else {
        gauge.min = {
          value: ([...series].map(e => e.gauge?.min).sort(function (a, b) { return a - b })[0] || null)?.nu_min,
          type: 'measures'
        }
      }
      let max = [...series].map(e => e.max).sort(function (a, b) { return b - a })[0] || null;
      // console.log('max', max);
      if (max !== null) {
        gauge.max = {
          value: max,
          type: 'range'
        }
      } else {
        gauge.max = {
          value: [...series].map(e => e.gauge?.max).sort(function (a, b) { return a - b })[0] || null,
          type: 'measures'
        }
      }
      // console.log('series', series);
      setLoading(false);
      setCustomConfig({ valuesResponse, lastObj, gauge, options: { ...customConfig.options, xaxis: { type: 'datetime', categories: categories } }, series })
    } else {
      setLoading(false);
      // setCustomConfig({ ...customConfig, series: [] });
    }
  }

  const getTypes = async () => {
    const types = await app.service('custom-devices-sections').find({ query: selected });
    // console.log('types', types);
    setTypesMeasures(types?.rows || []);
    if (!measureSelect) {
      // console.log('types', types);
      setMeasuresSelected((types?.rows || []).filter(e => e.bl_default));
    }
  }

  useEffect(() => {
    if (!!selected && !!measuresSelected.length) {
      getEvents(true);
    }
  }, [measuresSelected, range])

  async function suscribe() {
    const res = await app.service('suscribe').create({ channel: selected?.id });
    // console.log('resssss------------->', res);
  }
  async function unsuscribe() {
    const res = await app.service('suscribe').remove({ channel: selected?.id });
    // console.log('resssss------------->', res);
  }
  const handleObserverCreated = (item) => {
    // console.log('created event', item, selected);
    // console.log('item?.event?.id_dispositivo === selected.id_dispositivo', item?.event?.id_dispositivo === selected.id_dispositivo);
    if ((item?.event?.bl_close === true && item?.event?.id_dispositivo === selected.id_dispositivo) || (item?.bl_close === true && item?.id_dispositivo === selected.id_dispositivo)) {
      // console.log('event render');
      // setLastDataDate(moment())
      getEvents(false);
    }
  }
  const handleObserverUpdated = (item) => {
    // console.log('updated event', item, selected);
    if ((item?.event?.bl_close === true && item?.event?.id_dispositivo === selected.id_dispositivo) || (item?.bl_close === true && item?.id_dispositivo === selected.id_dispositivo)) {
      // console.log('event render');
      // setLastDataDate(moment())
      getEvents(false);
    }
  }
  useEffect(() => {
    suscribe();
    events.removeAllListeners();
    events.on('created', handleObserverCreated);
    events.on('updated', handleObserverUpdated);
    return () => {
      unsuscribe();
      events.off('created', handleObserverCreated);
      events.off('updated', handleObserverUpdated);
    }
  }, []);



  useEffect(() => {
    if (!defaultMeasure && !!selected?.id) {
      setCustomConfig({ ...customConfig, series: [] });
      getTypes();
    }
  }, [selected?.id])
  // useEffect(() => {
  //   // console.log('entro lastDataDate', lastDataDate);
  //   getEvents(false);
  // }, [range])
  const colorsAlarms = customConfig?.series?.map((serie, index) => {
    const alarmDevice = alarms.filter(e => e.id_tipoMedida === serie?.measure?.id && (serie?.gauge?.value > e.nu_max || serie?.gauge?.value < e.nu_min));
    switch (true) {
      case (alarmDevice.length > 0): {
        return 'red'
      }
      default: {
        return [(customConfig.series[0] || { color: '#00FFBB' }).gauge?.color, '#FFBB00', '#11FFAA', '#11FFFF'][index]
      }
    }
  });
  return (
    [
      <ButtonDateRange defaultValue={range} onChange={(e) => setRange(e)} limits={limitsRange} />,
      <Paper key={`selector-${selected?.id}`} style={{ marginBottom: 8, borderRadius: 10, width: '100%' }}>
        <Selector value={measureSelect} measuresSelected={measuresSelected} colors={colorsArray} options={typesMeasures}
          onSelect={(e) => setMeasuresSelected([e])}
          onSelectGroups={(e) => {
            if ((e.measures || []).filter(e => measuresSelected.includes(e)).length === (e.measures || []).length && (e.measures || []).length > 0) {
              setMeasuresSelected([]);
            } else {
              setMeasuresSelected(e.measures);
            }
          }} />
      </Paper>,
      <div key={`panel-${selected?.id}`} className={'grid-container'} style={{ width: '100%', maxWidth: 'calc(100% - 1px)', flexDirection: isMd ? 'row' : 'column-reverse', display: 'flex', height: isMd ? 400 : 'auto', position: 'relative' }}>
        <div key={'chart'} className={isMd ? 'left-component' : 'center-component'} style={{ paddingRight: isMd ? 8 : 0 }}>
          <Paper style={{ display: 'flex', borderRadius: 10, height: '100%', width: '100%', overflow: 'hidden', position: 'relative', border: '1px solid rgba(200,200,200,0.3)' }}>
            {
              !loading &&
              <ReactECharts
                id={`${measuresSelected.map(e => e.vc_name)}`}
                style={{ width: '100%', height: '100%', minHeight: 400 }}
                showLoading={loading && {
                  type: 'default',
                  opts: {
                    text: '',
                    maskColor: 'rgba(0, 0, 0, 0.8)',
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: 'normal',
                    // Font style. Available since `v5.0.1`.
                    fontStyle: 'normal',
                    // Font family. Available since `v5.0.1`.
                    fontFamily: 'sans-serif'
                  }
                }}
                option={{

                  animationDuration: 10000,
                  color: customConfig.series.length > 1 ? customConfig.series.map(e => e.color) : colorsArray,
                  backgroundColor: 'transparent',
                  title: {
                    show: false,
                    text: customConfig.series?.length > 1 ? 'Grupo' : customConfig.series[0]?.name || 'Grafica'
                  },
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'cross',
                      label: {
                        // backgroundColor: ,
                      }
                    },
                    valueFormatter: (value) => value.toFixed(2)
                  },
                  legend: {
                    data: customConfig.series.map(e => e.name),
                  },
                  toolbox: {
                    feature: {
                      saveAsImage: {}
                    }
                  },
                  grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    containLabel: false
                  },
                  xAxis: [
                    {
                      type: 'category',
                      boundaryGap: false,
                      data: customConfig.options?.xaxis?.categories || []
                    }
                  ],
                  yAxis: [
                    {
                      show: false,
                      type: 'value'
                    }
                  ],
                  series: customConfig.series.map((serie, index) => {
                    // console.log('serie', serie);
                    return ({
                      name: serie.name,
                      endLabel: {
                        show: true,
                        formatter: function (params) {
                          return params.seriesName + ': ' + (params.value).toFixed(2);
                        }
                      },
                      type: 'line',
                      // stack: 'Total',
                      smooth: true,
                      lineStyle: {
                        width: customConfig.series?.length > 1 ? 2 : 0.1,
                        opacity: 1,
                        shadowColor: 'black',
                        shadowOffsetX: 0,
                        shadowBlur: 10,
                      },
                      showSymbol: false,
                      areaStyle: {
                        show: customConfig.series?.length > 1 ? false : true,
                        opacity: customConfig.series?.length > 1 ? 0 : 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: 'rgba(128, 255, 165, 0.5)'
                          },
                          {
                            offset: 1,
                            color: 'rgba(1, 191, 236, 0.2)'
                          }

                          // {
                          //   offset: 0,
                          //   color: 'rgba(80, 150, 150, 0.8)'
                          // },
                          // {
                          //   offset: 1,
                          //   color: 'rgba(0, 100, 130, 0.3)'
                          // },
                          // {
                          //   offset: 0,
                          //   color: `${serie.color}FF`
                          // },
                          // {
                          //   offset: 1,
                          //   color: `${serie.color}BB`
                          // }
                        ])
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: serie.data?.map(e => e[1] || 0) || [],
                      markPoint: {
                        data: [
                          { type: 'max', name: 'Max' },
                          { type: 'min', name: 'Min' }
                        ]
                      },
                      markLine: {
                        data: [{ type: 'average', name: 'Avg' }]
                      }
                    })
                  })
                }}
                notMerge={true}
                lazyUpdate={true}
                theme={'dark'}
                // onChartReady={this.onChartReadyCallback}
                // onEvents={EventsDict}
                opts={{}}
              />
            }
          </Paper>
        </div>
        <div ref={refChart} key={'gauge'} className={isMd ? 'right-component' : 'center-component'} >
          <Paper style={{ display: 'flex', borderRadius: 10, height: '100%', width: '100%', overflow: 'hidden', border: '1px solid rgba(200,200,200,0.3)', position: 'relative' }}>
            <ReactECharts
              style={{ width: '100%', height: boundInner.height }}
              option={{
                color: colorsAlarms,
                backgroundColor: 'transparent',
                series: [
                  {
                    type: 'gauge',
                    min: customConfig.gauge?.min?.value * 0.9 || 0,
                    max: customConfig.gauge?.max?.value * 1.1 || 1,
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                      show: false
                    },

                    progress: {
                      show: true,
                      overlap: false,
                      roundCap: true,
                      clip: false,
                      itemStyle: {
                        width: 10,
                        borderWidth: 1,
                        borderColor: 'rgba(0,0,0,0.2)'
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        width: 20
                      }
                    },
                    splitLine: {
                      show: false,
                      distance: 0,
                      length: 10
                    },
                    axisTick: {
                      show: false
                    },
                    axisLabel: {
                      show: false,
                      distance: 50
                    },
                    legend: {
                      selectedMode: true
                    },
                    data: customConfig.series?.length === 1 ? [
                      {
                        value: (customConfig.series[0]?.gauge?.value || 0).toFixed(2),
                        name: 'Valor', title: {
                          show: false,
                          offsetCenter: ['0%', '-70%']
                        },
                        detail: {
                          show: false,
                          valueAnimation: true,
                          offsetCenter: ['0%', '-50%'],
                          precision: 2
                        }
                      },
                      {
                        value: (customConfig.series[0]?.gauge?.max || 0).toFixed(2),
                        name: 'Máximo',
                        title: {
                          offsetCenter: ['0%', '-70%']
                        },
                        detail: {
                          valueAnimation: true,
                          offsetCenter: ['0%', '-50%'],
                          precision: 2
                        }
                      },
                      // {
                      //   value: customConfig.gauge?.average || 0,
                      //   name: 'Promedio',
                      //   title: {
                      //     offsetCenter: ['0%', '10%']
                      //   },
                      //   detail: {
                      //     valueAnimation: true,
                      //     offsetCenter: ['0%', '30%'],
                      //     precision: 2
                      //   }
                      // },
                      {
                        value: (customConfig.series[0]?.gauge?.min || 0).toFixed(2),
                        name: 'Minimo',
                        title: {
                          offsetCenter: ['0%', '45%']
                        },
                        detail: {
                          valueAnimation: true,
                          offsetCenter: ['0%', '65%'],
                          precision: 2
                        }
                      }
                    ] : customConfig.series?.map((serie, index) => {
                      return ({
                        value: serie.gauge?.value ? (serie.gauge?.value).toFixed(2) : serie.gauge?.value || null,
                        name: serie.name, title: {
                          show: false,
                          offsetCenter: ['0%', '-70%']
                        },
                        detail: {
                          show: false,
                          valueAnimation: true,
                          offsetCenter: ['0%', '-50%'],
                          precision: 2
                        }
                      })
                    }),
                    title: {
                      fontSize: 14
                    },
                    detail: {
                      width: 'auto',
                      height: 14,
                      fontSize: 'auto',
                      color: 'inherit',
                      borderColor: 'inherit',
                      borderRadius: 20,
                      borderWidth: 1,
                      formatter: (e, i) => {
                        return `${e.toFixed(2)}${(customConfig.series[0] || {}).measure?.medida?.vc_abreviatura !== ' ' ? ' ' + (customConfig.series[0] || {}).measure?.medida?.vc_abreviatura : ''}`
                      }
                    }
                  }
                ]
              }}
              notMerge={false}
              lazyUpdate={false}
              theme={'dark'}
              // onChartReady={this.onChartReadyCallback}
              // onEvents={EventsDict}
              opts={{}}
              loadingOption={{ show: true }}
            />

            <Box width={'100%'} position='absolute' bottom={'10px'} p={0}
            >
              <Typography key={'date'} color={'inherit'} style={{ width: '100%', fontSize: '0.7rem', textAlign: 'center' }}>
                {'Fecha de registro: '}
                {!!customConfig.lastObj && moment(customConfig.lastObj?.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </Typography>
            </Box>
            <Box width={'100%'} position='absolute' flexDirection={'column'} justifyContent={'center'} display='flex' left={0} top={0} height={'100%'} p={0}
            >
              <Box width={'100%'} position='relative' flexDirection={customConfig.series.length > 1 ? 'row' : 'row'} justifyContent={'center'} display='flex'
              >
                <Box position='relative' alignSelf={'center'} flexDirection={customConfig.series.length > 1 ? 'column' : 'row'} justifyContent={'center'} display='flex'
                  style={{ borderRadius: '0%', width: boundInner.width * (customConfig?.series?.length === 1 ? 0.5 : 0.3), height: boundInner.width * 0.5 }}
                >
                  {/* {
                    customConfig.series.length > 1 ?
                      customConfig.series?.map((serie, index) => {
                        const ratio = (customConfig.series.length);
                        return (
                          <Typography key={serie.name} color={'inherit'} style={{ textAlign: 'center', width: 'auto', fontWeight: 700, fontSize: `${(500 / ratio)}%`, borderRadius: `${2 * ratio}rem`, border: `3px solid ${serie?.color}`, padding: '4px 8px', margin: 10 / customConfig.series?.length }}>
                            {`${(serie.gauge?.value || 0).toFixed(2)}${serie?.measure?.medida?.vc_abreviatura !== ' ' ? ' ' + serie?.measure?.medida?.vc_abreviatura : ''}`}
                          </Typography>
                        )
                      }) :
                      <Typography key={'date'} color={'inherit'} style={{ textAlign: 'center', width: 'auto', fontWeight: 700, fontSize: '150%', borderRadius: '2rem', border: `3px solid ${(customConfig.series[0] || {}).gauge?.color}`, padding: '8px 16px' }}>
                        {customConfig.series.length > 0 ? `${((customConfig.series[0] || {}).gauge?.value || 0).toFixed(2)}${(customConfig.series[0] || {})?.measure?.medida?.vc_abreviatura !== ' ' ? ' ' + (customConfig.series[0] || {})?.measure?.medida?.vc_abreviatura : ''}` : loading ? 'Cargando' : 'Sin datos'}
                      </Typography>
                  } */}

                  {
                    customConfig.series?.map((serie, index) => {
                      return (
                        <Box key={serie.name} color={'inherit'} style={{ textAlign: 'center', height: '100%', flexDirection: 'row', width: '100%', padding: 2 }}>
                          <Typography className='text' color={serie?.color} fontWeight={700} borderRadius={`10vw`} border={customConfig?.series?.length === 1 ? undefined : `1px solid ${serie.color}`} style={{ fontSize: '1em' || customConfig.series.length === 1 ? isMd ? 'auto' : '4vw' : isMd ? '1.5vw' : '3vw' }}>
                            {`${(serie.gauge?.value || 0).toFixed(2)}${serie?.measure?.medida?.vc_abreviatura !== ' ' ? ' ' + serie?.measure?.medida?.vc_abreviatura : ''}`}
                          </Typography>
                        </Box>
                      )
                    })
                  }

                </Box>

              </Box>
            </Box>
            <Box key={'IAR'} position='absolute' top={1} right={1} p={0}
            >
              <Box position={'relative'}>
                {!!measuresSelected.length && <IAReports values={customConfig.series} selected={selected} range={range} />}
              </Box>
            </Box>
            <Box key={'BAT'} position='absolute' bottom={1} right={1} p={0}
            >
              {!!typesMeasures.find((e) => e.vc_fieldName === 'BAT') && <BatteryIndicator size={60} value={bat || 0} />}
            </Box>
            {
              colorsAlarms.includes('red') &&
              <NotificationsActiveOutlined className={'alert'} style={{ position: 'absolute', top: 16, left: 16 }} />
            }
            {
              (!!loadingData.error || !!loadingData.loading) &&
              <Button onClick={() => getEvents()} fullWidth style={{ height: '100%', position: 'absolute', top: 0, left: 0 }}>
                {!!loadingData.loading ? <CircularProgress /> : <UpdateDisabledOutlined />}
              </Button>
            }
          </Paper>
        </div>
      </div >,
      !lite && <Typography key={'registers'} p={2} paddingLeft={3} width={'100%'} fontWeight='700'>
        {'Registros:'}
        <IconButton
          onClick={() => {
            const keys = {};
            customConfig.valuesResponse.forEach((v) => {
              v.registros.map(r => {
                keys[r.vc_field] = r.vc_field
              })
            })
            const datos = customConfig.valuesResponse.filter(e => !!e.registros.length).map((e) => {
              let obj = {
                nombre: selected.vc_nombre,
                registro: moment(e.createdAt).format('DD-MM-YYYY HH:mm:ss'),
              };
              Object.keys(keys).map((k) => {
                obj[k] = ''
              })
              e.registros.map(r => {
                obj[r.vc_field] = (r.nu_value * 1.00) ? (r.nu_value * 1.00).toFixed(2) : ''
              })
              return obj
            })
            const csv = Papa.unparse(datos);
            // console.log('datos', datos)
            // Crea un objeto Blob con el CSV y lo descarga como un archivo
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${selected.vc_nombre} - (${moment().format('DD-MM-YYYY HH:mm:ss')}).csv`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }}
          size='small' style={{ float: 'right' }}>
          <Download />
        </IconButton>
      </Typography>,
      !lite && <Box key={'registersitems'} marginBottom={2} style={{ position: 'relative', overflow: 'hidden', width: '100%', maxWidth: '100vw' }}>
        <Paper style={{ borderRadius: 10, width: '100%', overflowX: 'scroll', border: '1px solid rgba(200,200,200,0.3)' }}>
          <TableContainer component={Paper} style={{ maxWidth: '100%', width: '100%' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key={'fechaHeader'}>Fecha</TableCell>
                  {
                    customConfig.series.map((serie, i) => {
                      return (
                        <TableCell style={{ whiteSpace: 'nowrap' }} key={i} align="right">{`${serie.measure?.vc_tag || serie.measure?.vc_fieldName || ''}`}</TableCell>
                      )
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {([...((customConfig.series[0])?.data || [])]).reverse().filter((e, i) => i < 10).map((val, index) => {
                  return (
                    <TableRow key={`${val[0]}-${index}`}>
                      <TableCell key={'date'} align="left">
                        {!!val[0] && val[0]}
                      </TableCell>
                      {
                        customConfig.series?.map((serie, i) => {
                          const field = ([...(serie.data || [])].reverse()[index]) || null
                          return (
                            <TableCell key={i} align="right">
                              {field ? (field[1]).toFixed(2) : '-'}
                              {` ${serie.measure?.medida?.vc_abreviatura || ''}`}
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    ]
  );
}

export default ItemDashboard;
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconMask from '../IconMask';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import app from '../../services';
import firebase from '../../services/firebase';

import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemSecondaryAction, Popover, Popper, useMediaQuery } from '@mui/material';
import useMeasure from 'react-use-measure';
import Login from '../Login';
import { removeGlobalUser, removeInstitution, removeToken, setInstitution } from '../../redux/session';
import LinkDevicesButton from '../customForms/LinkDevicesButton';
import FavoritesButton from '../customForms/FavoritesButton';
import ThemeButton from '../customForms/ThemeButton';
import CatalogsIconButton from '../customForms/CatalogsIconButton';
import { citasUsuarios, instituciones, meetUsuarios, meets, usuarios } from '../../constants/models';
import { Helmet } from 'react-helmet';
import './MiniDrawer.css';
import { DeleteForeverOutlined, EditAttributesOutlined, EditOutlined, NotificationsActiveOutlined, NotificationsOffOutlined } from '@mui/icons-material';
import NotificationComponent from '../../common/NotificationComponent';
import { Zoom } from '@material-ui/core';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const routes = [
  { name: 'Dashboard', path: '/', icon: 'assets/icons/dashboard.svg' },
  { name: 'Inicio', path: '/home', icon: 'assets/icons/connect.svg' },
  // { name: 'Empresas', path: '/companies', icon: 'assets/icons/fabrica.svg' },
  { name: 'Locaciones', path: '/locations', icon: 'assets/icons/ubicacion.svg' },
  { name: 'Áreas', path: '/sections', icon: 'assets/icons/objetivo.svg' },
  { name: 'Activos industriales', path: '/industrial-assets', icon: 'assets/icons/motor.svg' },
  { name: 'Dispositivos', path: '/devices', icon: 'assets/icons/conexion.svg' },
  { name: 'Vinculaciones', path: '/links', icon: 'assets/icons/iteration.svg' },
  { name: 'Usuarios', path: '/users', icon: 'assets/icons/avatar.svg' },
  { name: 'Equipos de trabajo', path: '/work-groups', icon: 'assets/icons/group.svg' },
  { name: 'Soporte', path: '/video-call', icon: 'assets/icons/engineer.svg' },
  { name: 'Calendario', path: '/calendar', icon: 'assets/icons/calendario.svg' },
  { name: 'Catalogos', path: '/catalogs', icon: 'assets/icons/overlay.svg', allowRoles: ['663536e2-bdb4-4887-a313-c42d3e00bc37'] },
  { name: 'Configuración', path: '/config', icon: 'assets/icons/settings.svg' },
  { name: 'Dashboard', path: '/viewer', icon: 'assets/icons/panel.svg', hidden: true }
];

const filters = [
  { name: 'Activas', id: 'actives' },
  { name: 'Inactivas', id: 'inactivates' },
  { name: 'Todas', id: 'all' },
];

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter] = React.useState(filters[0]);
  const [ref, bounds] = useMeasure()
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const history = useHistory()
  const [path, setPath] = React.useState(history.location.pathname);
  const token = useSelector((state) => state.session.token);
  const [roles, setRoles] = React.useState([]);
  const [validateDelete, setValidateDelete] = React.useState(null);
  const [routesFilter, setRoutesFilter] = React.useState([]);
  const [meet, setMeet] = React.useState(null);
  const [institutions, setInstitutions] = React.useState([]);
  const user = useSelector((state) => state.session.globalUser);
  const institution = useSelector((state) => state.session.institution);
  const dispatch = useDispatch();
  const [newInstitution, setNewInstitution] = React.useState();
  const [showLogin, setShowLogin] = React.useState(!token)
  const rolesService = app.service('users-roles');
  const institutionService = app.service('institutions');
  
  React.useEffect(() => {
    // console.log('token', showLogin, token);
    if (!!token && showLogin) {
      setShowLogin(false);
      history.push('/')
    } else if (!token && !showLogin) {
      setShowLogin(true);
    }
    // console.log('history', history.location.pathname);
    return history.listen((location) => {
      // console.log(`You changed the page to: ${location.pathname}`)
      if (!token && location.pathname !== '/') {
        setShowLogin(true);
      } else if (location.pathname !== '/' && !showLogin) {
        setShowLogin(false);
      }
      setPath(location.pathname);
    })
  }, [history, token])
  // console.log('path-----', path)
  React.useEffect(() => {
    app.on('disconnect', (e) => {
      console.log('e', e)
    })
    app.reAuthenticate().then(() => {
      // show application page
      console.log('authenticate')
    }).catch(() => {
      // show login page
      console.log('no authenticate')
      setShowLogin(true);
      dispatch({ type: 'UNSET_USER', payload: null });

    });
  }, [])
  const logging = async () => {
    // await firebase.auth().signInAnonymously()
    if ('Notification' in window) {
      // Solicita permisos para las notificaciones
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Permiso de notificaciones concedido.');
            // Ahora puedes usar Firebase para obtener el token de registro
            // y enviarlo al servidor para enviar notificaciones push en el futuro
            firebase.messaging().getToken()
              .then((token) => {
                // console.log('Token de registro:', token, user);
                const service = app.service('usuarios')
                service.patch(user.id, { vc_push: token })
                  .then(updatedItem => {
                    console.log('Item updated:', updatedItem);
                  })
                  .catch(error => {
                    console.error('Error updating item:', error);
                  });
                // Envía este token al servidor para enviar notificaciones push a este dispositivo en el futuro
              })
              .catch((error) => {
                console.log('Error al obtener el token de registro:', error);
              });
          } else if (permission === 'denied') {
            console.log('Permiso de notificaciones denegado.');
          } else if (permission === 'default') {
            console.log('El usuario cerró la ventana de permisos sin tomar una decisión.');
          }
        })
        .catch((error) => {
          console.log('Error al solicitar permiso de notificaciones:', error);
        });
    } else {
      console.log('Las notificaciones no son compatibles en este navegador.');
    }
  }
  const getInstitutions = async () => {
    console.log('institutions ------->');
    setInstitutions([]);
    let params = undefined;
    switch (filter.id) {
      case ('actives'): {
        params = {
          query: {
            bl_activo: true
            // where: [{ bl_activo: true }]
          }
        };
        break;
      }
      case ('inactivates'): {
        params = {
          query: {
            bl_activo: false

            // where: [{ bl_activo: false }]
          }
        };
        break;
      }
      default: {
        params = undefined;

      }
    }
    const res = await institutionService.find(params);
    console.log('res ------->', filter, params, res);
    setInstitutions(res.data);
  }
  const getRoles = async () => {
    console.log('Roles ------->');
    setRoles([]);
    const res = await rolesService.find({ query: { $and: [{ id_usuario: user.id }] } });
    console.log('Roles ------->', res);
    setRoles(res.data);
  }
  React.useEffect(() => {
    if (token) {
      console.log('login');
      getInstitutions();
      getRoles();
      logging();
    }
  }, [token, filter])
  React.useEffect(() => {
    setRoutesFilter(routes.filter(e => e.allowRoles === undefined || (!!(e.allowRoles?.filter(i => !!(institution?.roles?.map(e => e.rol?.id).includes(i))).length))))
  }, [roles, institution])

  // console.log('history.location.pathname', history.location.pathname)
  const routeSelected = routes.find(e => history.location.pathname === e.path);
  const renderHeaderButton = () => {
    // console.log('history', history.location.pathname);
    switch (true) {
      // case (history.location.pathname === '/video-call'): {
      //   console.log('entro a video ----->')
      //   return <CatalogsIconButton model={meets} icon={'/assets/icons/meet.svg'} color={'white'} backgroundColor={'transparent'} />
      // }
      default: {
        return (
          <Box>
            <NotificationComponent />
            <CatalogsIconButton model={usuarios} icon={'/assets/icons/avatar.svg'} item={user} color={'white'} backgroundColor={'transparent'} />
          </Box>

        )
      }
    }
  }
  const isSystem = !!roles.find(e => e.rol?.vc_nombre === 'Sistema');
  const isAdmin = !!roles.find(e => e.rol?.vc_nombre === 'Admin');
  return ([
    (!!institution || history.location.pathname === '/video-call') &&
    <Box key='miniDrawer' sx={{ display: 'flex', height: '100%' }}>
      <Helmet >
        <title>{`Total Ground | ${path}`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <CssBaseline />
      <AppBar ref={ref} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {routeSelected.name}
          </Typography>
          <Grid container item xs='auto' position='absolute' direction={'row'} right={8} display={open && !isMd ? 'none' : 'flex'}>
            {renderHeaderButton()}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader >
          <ListItem key={'title'} disablePadding sx={{ display: 'block', position: 'absolute', left: 0, top: 8 }}>
            <ListItemButton
              disableRipple
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                dispatch(setInstitution(null));
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img src="assets/images/logo192.png" style={{ backgroundColor: 'white', height: 35, padding: 2, borderRadius: '50%' }} alt='' />
              </ListItemIcon>
              <ListItemText className='text-container' primary={`${institution?.vc_nombre || 'Total Ground'}`} sx={{ opacity: open ? 1 : 0, overflow: 'scroll' }} />
            </ListItemButton>
          </ListItem>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {routesFilter.filter(e => !e.hidden).map((route, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block', backgroundColor: route.path === path ? 'rgba(200,200,200,0.1)' : 'transparent' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => { history.push(route.path) }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <IconMask color={route.path === path ? theme.palette.primary.main : theme.palette.mode === 'dark' ? 'gray' : 'black'} url={route.icon} size={25} />
                </ListItemIcon>
                <ListItemText primary={route.name} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <ThemeButton mode='custom' drawerOpen={open} />
      </Drawer>
      <Box component="" style={{ paddingTop: bounds.height, width: '100%' }} sx={{ flexGrow: 1, p: 0, height: '100%' }}>
        {props.children}
      </Box>
      <Box p={1} position={'absolute'} bottom={0} right={0}>
        <Typography fontSize={10} style={{ color: 'gray' }}>
          {`Usuario: ${user?.vc_nombre} ${user?.vc_paterno || ''}`}
        </Typography>
        <Typography fontSize={10} style={{ color: 'gray', textAlign: 'right' }}>
          {`Version: ${'3.7.4' || process.env.REACT_APP_VERSION}`}
        </Typography>
      </Box>
    </Box>
    ,
    <Login
      key={'login-selector'}
      open={showLogin}
      setShowLogin={setShowLogin}
    />,
    <Dialog
      key={'institution-selector'}
      open={!showLogin && !institution}
      BackdropProps={{ style: { backgroundColor: 'black' } }}
    >
      <DialogTitle>
        {'Para iniciar selecciona o crea una empresa'}
      </DialogTitle>
      <DialogContent style={{ height: isMd ? '50vh' : '100%' }}>
        <ButtonGroup fullWidth size='small' style={{ marginBottom: 16 }}>
          {
            filters.map((item, index) => {
              return (
                <Button variant={item.id === filter.id ? 'contained' : 'outlined'} key={index} onClick={() => setFilter(item)}>
                  {item.name}
                </Button>
              )
            })
          }
        </ButtonGroup>
        {
          institutions.map((ins, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => {
                  if (ins.bl_activo) {
                    history.push('/');
                    dispatch(setInstitution({ ...ins, roles: roles.filter(e => e.id_institucion === ins.id || e.id_institucion === 'Global'|| e.id_institucion === null) }));
                  }
                }}
              >
                <Box paddingRight={3} style={{ opacity: ins.bl_activo ? 1 : 0.5 }}>
                  <IconMask color={theme.palette.mode === 'dark' ? 'white' : 'black'} url={'/assets/icons/fabrica.svg'} size={25} />
                </Box>
                <Typography style={{ opacity: ins.bl_activo ? 1 : 0.5 }}>
                  {ins.vc_nombre}
                </Typography>
                {
                  (!!isAdmin || !!isSystem) &&
                  <ListItemSecondaryAction
                    onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                    onMouseLeave={(e) => setAnchorEl(null)}
                  >

                    {
                      ins.bl_activo ?
                        <CatalogsIconButton model={instituciones} icon={'/assets/icons/settings.svg'} item={ins} color={'white'} backgroundColor={'transparent'} size={20} endButtons={
                          <Button

                            {...!isSystem ? {
                              disabled: true,
                              onMouseEnter: (e) => setAnchorEl(e.currentTarget),
                              onMouseLeave: (e) => setAnchorEl(null)
                            } : {}
                            }
                            variant='contained' style={{ backgroundColor: 'red' }} onClick={() => {
                              setValidateDelete({
                                ...ins,
                                action: () => {
                                  institutionService.patch(ins.id, { bl_activo: false })
                                }
                              })
                            }}>
                            <DeleteForeverOutlined style={{}} />
                          </Button>
                        } /> :
                        <CatalogsIconButton model={instituciones} icon={'/assets/icons/settings.svg'} item={ins} color={'white'} backgroundColor={'transparent'} size={20} endButtons={
                          <Button
                            {...!isSystem ? {
                              disabled: true,
                              onMouseEnter: (e) => setAnchorEl(e.currentTarget),
                              onMouseLeave: (e) => setAnchorEl(null)
                            } : {}
                            }
                            variant='contained' onClick={() => {
                              setValidateDelete({
                                ...ins,
                                action: () => {
                                  institutionService.patch(ins.id, { bl_activo: true })

                                }
                              })
                            }}>
                            {'Activar'}
                          </Button>
                        } />
                    }
                    {
                      !isSystem &&
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        onClose={(e) => setAnchorEl(null)}
                        disableRestoreFocus
                      >
                        <Typography

                          sx={{ p: 1 }}>Comunicate con TGOne para reactivar esta empresa</Typography>
                      </Popover>
                    }
                  </ListItemSecondaryAction>
                }
              </ListItemButton>
            );
          })
        }
        {
          instituciones.length === 0 &&
          <Button>
            {'!No hay resultados¡'}
          </Button>
        }
      </DialogContent>
      <DialogActions>
        <CatalogsIconButton key={'catIcon'} title={'Crear'} mode='title' variant='text' model={instituciones} />
        <Button
          key={'aceptar'}
          onClick={() => {
            dispatch(removeToken());
            dispatch(removeGlobalUser());
            dispatch(removeInstitution());
            setInstitutions([]);
          }}
        >
          {'Cerrar Sesión'}
        </Button>
      </DialogActions>
    </Dialog >,
    <Dialog
      key={'validate'}
      open={!!validateDelete}
      TransitionComponent={Zoom}
    >
      <DialogTitle>
        {`Desea eliminar ${validateDelete?.vc_nombre}`}
      </DialogTitle>
      <DialogContent>
        {`¿Esta acción ${validateDelete?.bl_activo ? 'inactivará' : 'reactivará'} nuevos eventos y registros de sus dispositivos?`}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setValidateDelete(null)}>
          {'Cancelar'}
        </Button>
        <Button variant='contained' color={validateDelete?.bl_activo ? 'error' : 'primary'} onClick={async () => {
          await validateDelete?.action();
          setValidateDelete(null);
          setInstitution([]);
          getInstitutions();
        }}>
          {validateDelete?.bl_activo ? 'Eliminar' : 'Activar'}
        </Button>
      </DialogActions>
    </Dialog>
  ]
  );
}
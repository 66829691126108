/* eslint-disable use-isnan */
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, colors, Divider, Grid, IconButton, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import ReactApexChart from "react-apexcharts"
import app from '../../services';
import ChartAir from '../ChartAir/index.tsx';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import ReactECharts from 'echarts-for-react';  // or var ReactECharts = require('echarts-for-react');
import * as echarts from 'echarts';
import moment from 'moment';
import './itemDashboardFavorites.css';
import GaugeAnimated from '../GaugeAnimated';
import useMeasure from 'react-use-measure';
import { useTheme } from '@mui/styles';
import Selector from '../ViewersLevels/Selector';
import measuresDefault from './measuresTotalView.json';
import IAReports from '../IAReports';
import BatteryIndicator from '../BatteryIndicator';
import { NotificationsActiveOutlined, NotificationsNone, NotificationsOff, NotificationsOffOutlined, UpdateDisabledOutlined } from '@mui/icons-material';
import { common } from '../../repositories';
import { useSelector } from 'react-redux';
const colorsArray = ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'];
function ItemDashboardFavorites({ selected, defaultMeasure, lite = false, alarms = [], stopData }) {
  const [loadingData, setLoadignData] = useState({ loading: false, error: null });
  const [bat, setBat] = useState(null);
  const token = useSelector((state) => state.session.token);
  const [disableAlert, setDisableAlert] = useState(false);
  const [lastDataDate, setLastDataDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refChart, boundInner] = useMeasure()
  const theme = useTheme()
  const [typesMeasures, setTypesMeasures] = useState(defaultMeasure ? [defaultMeasure] : []);
  const [measureSelect, setMeasureSelect] = useState();
  const [measuresSelected, setMeasuresSelected] = useState([]);
  const [customConfig, setCustomConfig] = useState({ options: {}, series: [] });
  const events = app.service('events');
  const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const getDataRetry = async () => {
    let response = null;

    try {
      setLoadignData({ loading: true });
      var baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3030';
      const url = `${baseURL}/devices-sections-measures/${selected?.id}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      // Verificar si la respuesta es exitosa (código de estado 2xx)
      if (res?.ok) {
        // Obtener la data
        response = await res?.json();
        // Manejar la data
        console.log('response', response);
      } else {
        // Manejar errores de la solicitud
        console.error('Error al realizar la solicitud:', res?.status, res?.statusText);
      }
      setLoadignData({ loading: false });
    } catch (error) {
      setLoadignData({ loading: false, error: error });
      console.log('error', error);
    }
    return response;
  }
  const getEvents = async (animationLoad = true) => {
    if (animationLoad) {
      setCustomConfig({ ...customConfig, options: null, series: [] })
      setLoading(true);
    }
    if (measuresSelected.length) {
      let response = null;
      response = await getDataRetry();

      if (!response?.json_data) {
        console.log('response error', response);
        return;
      }
      // console.log('get data events', response);
      const valuesResponse = (response.data || []);
      valuesResponse.sort(function (a, b) { return moment(a.createdAt) - moment(b.createdAt) })
      const bat = response.json_data.find(e => e.vc_field === 'BAT')?.nu_value || null;
      setBat(bat);
      let gauge = {};
      const categories = [moment(response?.createdAt).format('DD-MM-YY HH:mm')];
      const series = [];
      let lastObj = null;
      measuresSelected.map((meas, indexMeas) => {
        // console.log('meas', meas);
        let data = [];
        let media = { average: 0, total: 0, length: 0 };
        let min = null;
        let max = null;
        let last = null;
        let ranges = meas;
        // console.log('ranges', ranges);
        [response].map((event, index) => {
          const item = event.json_data.sort(function (a, b) { return moment(b.createdAt) - moment(a.createdAt) }).find(e => e.vc_field === meas.vc_fieldName);
          // console.log('item', item);
          if (item?.nu_value !== undefined) {
            let val = item.nu_value * 1;
            if (val !== NaN) {
              lastObj = { ...item, createdAt: event.createdAt }
              last = val
              data.push([moment(event.createdAt).format('DD-MM-YY HH:mm:ss'), val, event.createdAt])

            }
            // console.log('value------->', min, val, max);
            if (val >= max || max === null) {
              // console.log('entro a max');
              max = val;
            }
            if (val <= min || min === null) {
              // console.log('entro a min');
              min = val;
            }
            if (val !== NaN) {
              // console.log('entro a average');
              const mediaEdit = {
                average: ((media.total + val) / (media.length + 1)).toFixed(2),
                total: (media.total + val),
                length: (media.length + 1),
              }
              media = mediaEdit;
            }

          }
        });
        series.push({
          name: meas?.vc_nombre,
          data: data,
          measure: meas,
          color: colorsArray[indexMeas],
          min: meas.nu_min || null,
          max: meas.nu_max || null,
          gauge: {
            color: last > media.average ? '#FB0000' : colorsArray[indexMeas],
            value: last,
            max,
            min,
            ...media
          }
        })
      })

      let min = [...series].map(e => e.min).sort(function (a, b) { return a - b })[0] || null;
      // console.log('min', min);
      if (min !== null) {
        gauge.min = {
          value: min,
          type: 'range'
        }
      } else {
        gauge.min = {
          value: ([...series].map(e => e.gauge?.min).sort(function (a, b) { return a - b })[0] || null)?.nu_min,
          type: 'measures'
        }
      }
      let max = [...series].map(e => e.max).sort(function (a, b) { return b - a })[0] || null;
      // console.log('max', max);
      if (max !== null) {
        gauge.max = {
          value: max,
          type: 'range'
        }
      } else {
        gauge.max = {
          value: [...series].map(e => e.gauge?.max).sort(function (a, b) { return a - b })[0] || null,
          type: 'measures'
        }
      }
      // console.log('series', series);
      setLoading(false);
      setCustomConfig({ lastObj, gauge, options: { ...customConfig.options, xaxis: { type: 'datetime', categories: categories } }, series })
    } else {
      setLoading(false);
      getTypes();
      // setCustomConfig({ ...customConfig, series: [] });
    }
  }

  const getTypes = async () => {
    const types = await app.service('custom-devices-sections').find({ query: selected });
    // console.log('types', types);
    setTypesMeasures(types?.rows || []);
    if (!measureSelect) {
      console.log('types', types);
      console.log('measureSelect', measureSelect)
      setMeasuresSelected((types?.rows || []).filter(e => e.bl_default));
    } else {
      console.log('measureSelect vacio', measureSelect)
    }
  }
  useEffect(() => {
    if (!stopData) {
      suscribe();
    }
  }, [stopData])
  useEffect(() => {
    if (!!selected && !!measuresSelected.length && !stopData) {
      getEvents(true);
    }
  }, [measuresSelected, stopData])

  async function suscribe() {
    const res = await app.service('suscribe').create({ channel: selected?.id });
    console.log('resssss------------->', res);
  }
  async function unsuscribe() {
    const res = await app.service('suscribe').remove({ channel: selected?.id });
    console.log('resssss------------->', res);
  }
  const handleObserverCreated = (item) => {
    console.log('created event', item, selected, measureSelect, measuresSelected);
    // console.log('item?.event?.id_dispositivo === selected.id_dispositivo', item?.event?.id_dispositivo === selected.id_dispositivo);
    if ((item?.event?.bl_close === true && item?.event?.id_dispositivo === selected.id_dispositivo) || (item?.bl_close === true && item?.id_dispositivo === selected.id_dispositivo)) {
      console.log('event render');
      // setLastDataDate(moment())
      getEvents(true);
    }
  }
  const handleObserverUpdated = (item) => {
    console.log('updated event', item, selected);
    if ((item?.event?.bl_close === true && item?.event?.id_dispositivo === selected.id_dispositivo) || (item?.bl_close === true && item?.id_dispositivo === selected.id_dispositivo)) {
      console.log('event render');
      // setLastDataDate(moment())
      getEvents(true);
    }
  }
  useEffect(() => {
    suscribe();
    events.removeAllListeners();
    events.on('created', getEvents);
    events.on('updated', getEvents);
    // events.on('created', handleObserverCreated);
    // events.on('updated', handleObserverUpdated);
    return () => {
      unsuscribe();
      events.off('created', handleObserverCreated);
      events.off('updated', handleObserverUpdated);
    }
  }, [])


  useEffect(() => {
    console.log('get type useeffect')
    getTypes();
    if (!defaultMeasure) {
      setCustomConfig({ ...customConfig, series: [] });
    }
  }, [selected?.id])
  // useEffect(() => {
  //   console.log('entro lastDataDate', lastDataDate);
  //   getEvents(false);
  // }, [lastDataDate])
  const colorsAlarms = customConfig?.series?.map((serie, index) => {
    const alarmDevice = alarms.filter(e => e.medida?.vc_fieldName === serie?.measure?.vc_fieldName && (serie?.gauge?.value > e.nu_max || serie?.gauge?.value < e.nu_min));
    // console.log('alarms', alarms)
    // console.log('alarmDevice', alarmDevice)
    // console.log('serie', serie)
    // console.log('measure select', measureSelect)
    switch (true) {
      case (alarmDevice.length > 0): {
        return 'red'
      }
      default: {
        return [(customConfig.series[0] || { color: '#00FFBB' }).gauge?.color, '#FFBB00', '#11FFAA', '#11FFFF'][index]
      }
    }
  });

  return (
    [

      <div ref={refChart} key={'gauge'} style={{ width: '100%', height: boundInner.width, position: 'relative' }}>
        <Paper className={!disableAlert && colorsAlarms.includes('red') ? 'alertBox' : undefined} style={{ display: 'flex', borderRadius: 10, height: '100%', width: '100%', overflow: 'hidden', border: '1px solid rgba(200,200,200,0.3)' }}>
          <ReactECharts
            style={{ width: boundInner.width, height: boundInner.width }}
            option={{
              color: colorsAlarms,
              backgroundColor: 'transparent',
              series: [
                {
                  type: 'gauge',
                  min: customConfig.gauge?.min?.value * 0.9 || 0,
                  max: customConfig.gauge?.max?.value * 1.1 || 1,
                  startAngle: 90,
                  endAngle: -270,
                  pointer: {
                    show: false
                  },

                  progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    clip: false,
                    itemStyle: {
                      width: 10,
                      borderWidth: 1,
                      borderColor: 'rgba(0,0,0,0.2)'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      width: 20
                    }
                  },
                  splitLine: {
                    show: false,
                    distance: 0,
                    length: 10
                  },
                  axisTick: {
                    show: false
                  },
                  axisLabel: {
                    show: false,
                    distance: 50
                  },
                  legend: {
                    selectedMode: true
                  },
                  data: false && customConfig.series?.length === 0 ? [
                    {
                      value: (customConfig.series[0]?.gauge?.value || 0).toFixed(2),
                      name: 'Valor', title: {
                        show: false,
                        offsetCenter: ['0%', '-70%']
                      },
                      detail: {
                        show: false,
                        valueAnimation: true,
                        offsetCenter: ['0%', '-50%'],
                        precision: 2
                      }
                    },
                    {
                      value: (customConfig.series[0]?.gauge?.max || 0).toFixed(2),
                      name: 'Máximo',
                      title: {
                        offsetCenter: ['0%', '-70%']
                      },
                      detail: {
                        valueAnimation: true,
                        offsetCenter: ['0%', '-50%'],
                        precision: 2
                      }
                    },
                    // {
                    //   value: customConfig.gauge?.average || 0,
                    //   name: 'Promedio',
                    //   title: {
                    //     offsetCenter: ['0%', '10%']
                    //   },
                    //   detail: {
                    //     valueAnimation: true,
                    //     offsetCenter: ['0%', '30%'],
                    //     precision: 2
                    //   }
                    // },
                    {
                      value: (customConfig.series[0]?.gauge?.min || 0).toFixed(2),
                      name: 'Minimo',
                      title: {
                        offsetCenter: ['0%', '45%']
                      },
                      detail: {
                        valueAnimation: true,
                        offsetCenter: ['0%', '65%'],
                        precision: 2
                      }
                    }
                  ] : customConfig.series?.map((serie, index) => {
                    return ({
                      value: serie.gauge?.value ? (serie.gauge?.value).toFixed(2) : serie.gauge?.value || null,
                      name: serie.name, title: {
                        show: false,
                        offsetCenter: ['0%', '-70%']
                      },
                      detail: {
                        show: false,
                        valueAnimation: true,
                        offsetCenter: ['0%', '-50%'],
                        precision: 2
                      }
                    })
                  }),
                  title: {
                    fontSize: 14
                  },
                  detail: {
                    width: 'auto',
                    height: 14,
                    fontSize: 'auto',
                    color: 'inherit',
                    borderColor: 'inherit',
                    borderRadius: 20,
                    borderWidth: 1,
                    formatter: (e, i) => {
                      return `${e.toFixed(2)}${(customConfig.series[0] || {}).measure?.medida?.vc_abreviatura !== ' ' ? ' ' + (customConfig.series[0] || {}).measure?.medida?.vc_abreviatura : ''}`
                    }
                  }
                }
              ]
            }}
            notMerge={false}
            lazyUpdate={false}
            theme={'dark'}
            // onChartReady={this.onChartReadyCallback}
            // onEvents={EventsDict}
            opts={{}}
            loadingOption={{ show: true }}
          />
          {/* <Box width={'100%'} position='absolute' bottom={'10px'} p={0}
            >
              <Typography key={'date'} color={'inherit'} style={{ width: '100%', fontSize: '0.7rem', textAlign: 'center' }}>
                {'Fecha de registro: '}
                {!!customConfig.lastObj && moment(customConfig.lastObj?.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </Typography>
            </Box> */}
          <Box width={'100%'} position='absolute' flexDirection={'column'} justifyContent={'center'} display='flex' left={0} top={0} height={'100%'} p={0}
          >
            <Box width={'100%'} height={'100%'} position='relative' flexDirection={customConfig.series.length > 1 ? 'row' : 'row'} justifyContent={'center'} display='flex'
            >
              <Box position='relative' alignSelf={'center'} flexDirection={customConfig.series.length > 1 ? 'column' : 'row'} justifyContent={'center'} display='flex'
                style={{ borderRadius: '50%', width: '40%', height: '40%' }}
              >
                {/* {
                  customConfig.series.length > 1 ?
                    customConfig.series?.map((serie, index) => {
                      const ratio = (customConfig.series.length);
                      return (
                        <Typography key={serie.name} color={'inherit'} style={{ textAlign: 'center', width: 'auto', fontWeight: 400, fontSize: `${isMd ? 90 : 10}%`, borderRadius: `${2 * ratio}rem`, border: `1px solid ${serie?.color}`, padding: `${0.1}vw ${0.2}vw`, margin: 10 / customConfig.series?.length }}>
                          {`${(serie.gauge?.value || 0).toFixed(2)}${serie?.measure?.medida?.vc_abreviatura !== ' ' ? ' ' + serie?.measure?.medida?.vc_abreviatura : ''}`}
                        </Typography>
                      )
                    }) :
                    <Typography key={'date'} color={'inherit'} style={{ textAlign: 'center', width: 'auto', fontWeight: 400, fontSize: `${isMd ? 90 : 10}%`, borderRadius: '2rem', border: `1px solid ${(customConfig.series[0] || {}).gauge?.color}`, padding: '8px 16px' }}>
                      {customConfig.series.length > 0 ? `${((customConfig.series[0] || {}).gauge?.value || 0).toFixed(2)}${(customConfig.series[0] || {})?.measure?.medida?.vc_abreviatura !== ' ' ? ' ' + (customConfig.series[0] || {})?.measure?.medida?.vc_abreviatura : ''}` : loading ? 'Cargando' : 'Sin datos'}
                    </Typography>
                } */}
                {
                  customConfig.series?.map((serie, index) => {
                    return (
                      <Box key={serie.name} className='container-text' color={'inherit'} style={{ textAlign: 'center', height: `calc(${100 / customConfig?.series?.length}%)`, flexDirection: 'row', width: '100%' }}>
                        <Typography className='text' color={serie?.color} fontWeight={700} borderRadius={`${50 / customConfig?.series?.length}px`} border={`1px solid ${serie.color}`} style={{ fontSize: customConfig.series.length === 1 ? isMd ? '1.5vw' : '4vw' : isMd ? '1vw' : '3vw' }}>
                          {`${(serie.gauge?.value || 0).toFixed(2)}${serie?.measure?.medida?.vc_abreviatura !== ' ' ? ' ' + serie?.measure?.medida?.vc_abreviatura : ''}`}
                        </Typography>
                      </Box>
                    )
                  })
                }
              </Box>

            </Box>
          </Box>
          {
            colorsAlarms.includes('red') &&
            <IconButton onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDisableAlert(!disableAlert);
            }} size='small' style={{ position: 'absolute', top: '0.5vw', right: '0.5vw' }}>
              {
                disableAlert ?
                  <NotificationsOffOutlined /> :
                  <NotificationsActiveOutlined className={disableAlert ? undefined : 'alert'} />
              }
            </IconButton>
          }
          {
            (!!loadingData.error || !!loadingData.loading) &&
            <Button onClick={() => getEvents()} fullWidth style={{ height: '100%', position: 'absolute', top: 0, left: 0 }}>
              {!!loadingData.loading ? <CircularProgress /> : <UpdateDisabledOutlined />}
            </Button>
          }
        </Paper>
      </div>
    ]
  );
}

export default ItemDashboardFavorites;
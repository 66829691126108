import transitions from '@material-ui/core/styles/transitions';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSpeechRecognition, useSpeechSynthesis } from 'react-speech-kit';
import IALayer from './IALayer';
import 'moment/locale/es'
import IconMask from '../IconMask';
import { MicExternalOffOutlined, MicExternalOnOutlined, MicNoneOutlined, MicOff, MicOffOutlined, MicOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import app from '../../services';
import ChartLive from '../ChartLive';
import { TransitionUp } from '../../common/TransitionComponents';
import { useTheme } from '@mui/styles';
import LineEchart from '../echarts/LineEchart';
import VideoCallView from '../../views/VideoCallView';
const IAReports = ({ values, selected, range = [] }) => {
  const user = useSelector((state) => state.session.globalUser);
  const [showMeet, setShowMeet] = useState(false);
  const [value, setValue] = useState('');
  const [report, setReport] = useState(null);
  const speech = useSpeechSynthesis();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult: (result) => {
      setValue(result);
    }
  });
  async function getReport(type, series = [],) {
    let iaGlobal = `\n`
    iaGlobal = iaGlobal + `Fecha: ${moment().locale('es-MX').format('DD MMMM YYYY')}\n`
    iaGlobal = iaGlobal + `Hora: ${moment().locale('es-MX').format('HH:mm:ss')}\n`
    iaGlobal = iaGlobal + `Supervisor: ${user?.vc_nombre || ' '} ${user?.vc_paterno || ' '} ${user?.vc_materno || ' '}\n\n`
    iaGlobal = iaGlobal + `Se realiza análisis automatico de los registros de los dispositivos seleccionados:\n\n`
    // console.log('selected', selected)
    iaGlobal = iaGlobal + `Dispositivo: ${selected?.vc_nombre || 'No identificado'}.\nID: ${selected?.id_dispositivo || 'No identificado'}\n\n`
    const measures = [...series].map(meas => {
      const data = meas.data || [];
      let min = null;
      let max = null;
      let media = 0;
      let number = 0;
      let total = 0;
      let ia = ''
      const startDate = moment(data[0][2] || null);
      const endDate = moment(([...data].pop())[2] || null);
      console.log('range ------------>', range);
      const startDateString = !!range[0] ? moment(range[0]).locale('es-MX').format('DD MMMM YYYY') : startDate.locale('es-MX').format('DD MMMM YYYY');
      const endDateString = !!range[1] ? moment(range[1]).locale('es-MX').format('DD MMMM YYYY') : endDate.locale('es-MX').format('DD MMMM YYYY');
      const lapse = Math.round(endDate.diff(startDate, 'hours') / 24);
      let lapseString = `${lapse} días`.replace('-', '');
      if (lapse === 0) {
        lapseString = `${endDate.diff(startDate, 'hours')} horas`.replace('-', '');
      }
      data.forEach(register => {
        const value = register[1] || null;
        min = !!min && min[1] < value ? min : register;
        max = !!max && max[1] > value ? max : register;
        total = total + value || 0;
      })
      const variability = max[1] - min[1];
      media = total / data.length;

      ia = `Se encontraron ${data.length} registros de ${meas.name} entre el ${startDateString} y el ${endDateString}, un reporte que comprende ${lapseString}.`
      ia = ia + `\nCon un valor más bajo registrado de ${(min[1]).toFixed(2)} el cuál fue generado el pasado ${moment(min[2]).locale('es-MX').format('DD MMMM YYYY')}, así mismo un valor máximo de ${max[1].toFixed(2)} generado el pasado ${moment(max[2]).locale('es-MX').format('DD MMMM YYYY')}, durante la revisión del algoritmo de inteligencia artificial se encuentra un promedio de ${media.toFixed(2)}`
      ia = ia + `\nLa variabilidad entre el valor maximo y minimo fue de ${(variability).toFixed()} de su unidad de medida.`

      return {
        ...meas,
        ia
      }
      iaGlobal = iaGlobal + `\n\n${ia}`;
    })
    return {
      header: iaGlobal,
      ia: iaGlobal,
      measures
    }
  }
  useEffect(() => {
    if (!listening) {
      const IA = IALayer(value);
      let text = '';
      IA.commands.map(item => {
        if (text === '') {
          text = 'Se reconocen los siguientes comandos de voz:'
        }
        text = text + `\n${item.raw}`;
      })
      IA.dates.map(item => {
        moment.locale('es');
        console.log('dates', item.value)
        let stringDate = moment(item.value).locale('es-MX').format('DD MMMM YYYY')
        if (!text.toLowerCase().includes('desde')) {
          text = text + `\nDesde el ${stringDate}`;
        } else {
          text = text + ` hasta el ${stringDate}, reporte de ${item.lapse} días`;
        }
      })
      // console.log('tesxt', text)
      speech.cancel();
      speech.speak({ text: text })
      // getData();

    }
  }, [listening])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReport(null)
    setShowMeet(false)

  };
  async function getData() {
    if (values) {
      const report = await getReport('reporte total', values);
      setReport(report);
    }
  }
  useEffect(() => {
    if (values.length) {
      getData();
    } else {
      setReport(null)
    }
  }, [values, range])
  useEffect(() => {
    // console.log('holaaa')
    // speech.speak({ text: `Hola ${user.vc_nombre} en qué puedo ayudarte` })
  }, [])
  const getStyles = () => {
    switch (true) {
      case (showMeet && !isMd): {
        return {
          container: { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' },
          first: { display: 'flex', width: '100%', height: '50%' },
          second: { display: 'flex', width: '100%', height: '50%' }
        }
      }
      case (showMeet && isMd): {
        return {
          container: { display: 'flex', flexDirection: 'row', width: '100%', height: '100%' },
          first: { display: 'flex', width: '50%', height: '100%' },
          second: { display: 'flex', width: '50%', height: '100%', overflowY: 'scroll' }
        }
      }
      default: {
        return {
          container: { display: 'flex', width: '100%', height: '100%' },
          first: { display: 'flex', width: '100%', height: '100%', overflow: 'hidden' },
          second: { display: 'flex', width: 0, height: 0 }
        }
      }
    }
  }
  return ([
    <IconButton key={'iconButtonIAR'} size='large' variant="outlined"
      onClick={handleClickOpen}
    >
      <IconMask color={theme.palette.mode === 'dark' ? 'white' : 'gray'} url={'assets/icons/ia-tgone.svg'} size={35} />
    </IconButton>,
    open ? <Dialog
      key={'IAR'}
      open={open}
      TransitionComponent={TransitionUp}
      keepMounted
      onClose={handleClose}
      fullScreen={!isMd || showMeet}
      fullWidth={showMeet}
    >
      <DialogTitle>
        <Box display={'flex'} justifyContent={'center'}>
          <IconMask color='gray' url={'/assets/icons/ia-tgone.svg'} size={50} />
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography textAlign={'left'} style={{ fontSize: 14, fontWeight: 700 }}>
            {'By Total Ground'}
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent style={{ overflow: 'scroll', width: '100%', padding: 0 }}>
        <div style={getStyles().container}>
          <Box key={'1'} style={{ ...getStyles().first }}>
            <Paper style={{ padding: 16, overflowY: 'scroll', height: '100%' }}>
              {
                !!report && <Box key={'header'} style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
                  <Typography textAlign={'left'} style={{ fontSize: 16, fontWeight: 700 }}>
                    {'Reporte'}
                  </Typography>
                  <Typography textAlign={'justify'}>
                    {report?.header}
                  </Typography>
                </Box>
              }
              {
                !report && <Typography>
                  {'Puedes hacer la petición de reportes automaticos'}
                </Typography>
              }
              {
                report?.measures?.map((item, index) => {
                  return (
                    <Box key={index} style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
                      <Typography textAlign={'left'} style={{ fontSize: 16, fontWeight: 700 }}>
                        {item.name}
                      </Typography>
                      <Divider />
                      <Typography textAlign={'justify'}>
                        {item.ia}
                      </Typography>
                      <Box key={'chartbox'} marginBottom={2} marginTop={2} style={{ borderRadius: 10, height: 200, position: 'relative', width: '100%', overflow: 'hidden' }}>
                        <LineEchart title={item.name} series={[item]} animationDuration={1} gradient />
                      </Box>
                    </Box>
                  )
                })
              }
            </Paper>
          </Box>
          <div key={'2'} style={{ ...getStyles().second }}>
            <Box style={{ padding: 0, position: 'relative', width: '100%', overflow: 'hidden', height: '100%' }} >
              {!!showMeet && <VideoCallView fixed={false} onClose={() => setShowMeet(false)} />}
            </Box>
          </div>
        </div>
        {/* <Grid container direction={isMd ? 'row' : 'column'} height={'100%'} width={'90%'} overflow={'hidden'} style={{ backgroundColor: 'yellow' }}>
          <Grid key={'first'} item xs={showMeet ? 6 : 12} style={{ backgroundColor: 'red' }}>
            <Paper style={{ padding: 16, overflowY: 'scroll', height: '100%' }}>
              {
                !!report && <Box key={'header'} style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
                  <Typography textAlign={'left'} style={{ fontSize: 16, fontWeight: 700 }}>
                    {'Reporte'}
                  </Typography>
                  <Typography textAlign={'justify'}>
                    {report?.header}
                  </Typography>
                </Box>
              }
              {
                !report && <Typography>
                  {'Puedes hacer la petición de reportes automaticos'}
                </Typography>
              }
              {
                report?.measures?.map((item, index) => {
                  return (
                    <Box key={index} style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
                      <Typography textAlign={'left'} style={{ fontSize: 16, fontWeight: 700 }}>
                        {item.name}
                      </Typography>
                      <Divider />
                      <Typography textAlign={'justify'}>
                        {item.ia}
                      </Typography>
                      <Box key={'chartbox'} marginBottom={2} marginTop={2} style={{ borderRadius: 10, height: 200, position: 'relative', width: '100%', overflow: 'hidden' }}>
                        <LineEchart title={item.name} series={[item]} animationDuration={1} gradient />
                      </Box>
                    </Box>
                  )
                })
              }
            </Paper>
          </Grid>
          {
            !!showMeet &&
            <Grid key={'second'} item xs={6} style={{ backgroundColor: 'blue' }} >
              <Box style={{ padding: 0, position: 'relative', width: '100%', overflow: 'hidden', height: '100%' }} >
                <VideoCallView fixed={false} onClose={() => setShowMeet(false)} />
              </Box>
            </Grid>
          }
        </Grid> */}
      </DialogContent>
      <DialogActions style={{ borderTop: 'solid 0.5px rgba(200,200,200,0.3)' }}>
        <IconButton variant={'contained'} onClick={() => {
          setShowMeet(!showMeet);
        }}>
          <IconMask color={showMeet ? theme.palette.primary[500] : 'gray'} url={'assets/icons/engineer.svg'} size={30} />
        </IconButton>
        <IconButton color='primary' variant={'contained'} onClick={() => {
          try {
            if (speech.speaking) {
              speech.cancel()
            } else {
              speech.speak({ text: report.ia })
            }
          } catch (error) {

          }
        }}>
          <IconMask color={showMeet ? theme.palette.primary[500] : 'gray'} url={'assets/icons/speaking.svg'} size={30} />
        </IconButton>

        <Box display={'flex'} justifyContent={'center'}>
          {
            listening ?
              <IconButton variant={'contained'} onClick={stop}>
                <IconMask color={showMeet ? theme.palette.primary[500] : 'gray'} url={'assets/icons/microphone.svg'} size={30} />
              </IconButton>
              :
              <IconButton color='primary' variant={'contained'} onClick={() => {
                try {
                  listen()
                } catch (error) {
                  setValue(JSON.stringify(error));
                }
              }}>
                <IconMask color={showMeet ? theme.palette.primary[500] : 'gray'} url={'assets/icons/microphone.svg'} size={30} />
              </IconButton>
          }
        </Box>
        <Button
          onClick={handleClose}
        >
          {'Cancelar'}
        </Button>
      </DialogActions>
    </Dialog> : null,
  ]
  );
};
export default IAReports;